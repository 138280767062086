/* --------------------------------------------------------------
    Import CSS Dependencies
-------------------------------------------------------------- */
@import 'colors';
@import 'icons';
@import 'fonts';
@import 'default';
@import 'nav';
@import 'buttons';
/* --------------------------------------------------------------
    Start Project CSS
-------------------------------------------------------------- */
html {
  position: relative;
  height: 100%;
  background-color: #fff;
  // overflow-y: scroll;
  // -webkit-font-smoothing: antialiased;
  // -moz-osx-font-smoothing: grayscale;
}
body {
  height: 100%;
  font-family: var(--font-family);
  text-rendering: optimizeLegibility;
  color: #555555;
}
.wrap {
  padding: 30px 0;
}
.container-fluid.but-fixed {
  max-width: 1356px;
}
@media (min-width: 1400px) {
  .container-fluid.but-fixed {
    padding-right: calc(var(--bs-gutter-x) * 0.5);
    padding-left: calc(var(--bs-gutter-x) * 0.5);
  }
}
.footer {
  padding-top: 20px;
  padding-bottom: 15px;
  width: 100%;
  background-color: #f5f5f5;
  color: #999999;
  border-top: 1px solid #e4e4e4;
  font-size: 0.9rem;
  p {
    margin: 0 0 5px 0;
  }
  a {
    color: #999999;
  }
  .social a {
    border: 0;
  }
  img {
    display: inline-block;
  }
}

/* --------- asc styles --------- */
#header {
  // background: rgba(255, 255, 255, 0.8);
  float: none;
  a.logo {
    float: left;
    border: 0;
    width: 80%;
    max-width: 450px;
    img {
      display: inline-block;
      margin-right: 5px;
      vertical-align: middle;
      width: auto;
      height: auto;
      max-width: 40px;
    }
    span.big,
    span.small {
      display: inline;
      font-size: 1.5em;
      font-weight: 500;
      color: #555;
      line-height: 1em;
      vertical-align: middle;
    }
    span.big {
      font-weight: 600;
      font-family: 'Roboto Slab', var(--font-family);
      line-height: 1.2;
      .dot-com {
        font-size: 1rem;
      }
    }
    span.small {
      display: none;
      margin-top: 0;
      font-size: 18px;
      letter-spacing: 0.03rem;
      font-family: var(--font-family);
      font-weight: normal;
      font-style: italic;
    }
  }
  #support {
    display: none;
    float: right;
    margin: 0 0 0 0;
    width: auto;
    p {
      float: right;
      margin: 2px 12px 0 0;
      strong {
        font-size: 16px;
      }
    }
    a.login {
      float: right;
      margin: 0 0 0 0;
      padding: 0 12px 2px 12px;
      font-weight: normal;
      color: #fff;
      background: $theme-primary;
      text-decoration: none;
      border-radius: 0 0 4px 4px;
      border: 0;
      &:before {
        display: inline-block;
        margin-right: 5px;
        width: inherit;
        height: inherit;
        content: '\e828';
        color: inherit;
        font-family: 'icons';
        font-weight: normal;
        text-align: center;
        font-size: 105%;
        vertical-align: top;
      }
      &:hover {
        background: lighten($theme-primary, 7.5%);
      }
    }
    span.phone {
      display: none;
      padding-right: 8px;
      border-right: 1px dotted #333;
    }
    a.livechat {
      float: right;
      margin: 0 0 0 10px;
      font-weight: normal;
      color: #555;
      &:before {
        display: inline-block;
        margin-right: 5px;
        width: inherit;
        height: inherit;
        content: '\e830';
        color: inherit;
        font-family: 'icons';
        font-weight: normal;
        text-align: center;
        font-size: 105%;
        vertical-align: top;
      }
    }
  }
}
#header-wrapper {
  background: #fff url(../images/header-bg-small.jpg) no-repeat 50% 50% / cover;
  #header-wrap {
    background: rgba(255, 255, 255, 0.7);
  }
}
#banner {
  h1,
  h2 {
    text-shadow: 1px 1px #fff;
  }
  #pitch {
    background: rgba(255, 255, 255, 0.75);
    max-width: 650px;
    ul {
      font-weight: 600;
      li:before {
        color: #f96;
      }
    }
  }
}
#steps-wrap {
  background-color: #f7f4f4;
  .icon h3 {
    margin: 0 0 10px 0;
    position: relative;
  }
  .icon h3 {
    &:before {
      display: inline-block;
      margin: 0 10px;
      width: 40px;
      height: 40px;
      content: '';
      background: transparent url(../images/steps-icon.png) no-repeat 0 0 / cover;
      vertical-align: middle;
      transition: opacity 0.25s ease-out 0.25s;
      transition-delay: 0.1s;
    }
    &.step-2:before {
      background-position: -40px 0;
      transition-delay: 0.2s;
    }
    &.step-3:before {
      background-position: -80px 0;
      transition-delay: 0.3s;
    }
    span {
      display: inline-block;
      vertical-align: middle;
    }
  }
}
#reviews-wrap {
  blockquote {
    margin-bottom: 10px;
    font-size: 1.35em;
    line-height: 1.4em;
    font-weight: 400;
    color: #444;
    font-family: Georgia, 'Open Sans', 'Trebuchet MS', Verdana, Arial, Helvetica, sans-serif;
    font-style: italic;
    p {
      color: #777;
      &:after,
      &:before {
        margin-right: 8px;
        display: inline-block;
        content: '\201C';
        color: #ccc;
        font-family: serif;
        font-size: 36px;
        font-weight: 700;
        vertical-align: middle;
        font-style: normal;
      }
      &:after {
        margin-left: 8px;
        content: '\201D';
      }
      a {
        color: inherit;
        border: 0;
      }
    }
  }
  .more-reviews {
    font-family: 'Fjalla One', 'Open Sans', 'Trebuchet MS', Verdana, Arial, Helvetica, sans-serif;
    .shopperlink {
      margin: 0 5px;
      display: inline-block;
      vertical-align: middle;
    }
  }
}
.tabs {
  li {
    margin-right: 1px;
    margin-bottom: 0;
    margin-top: 0;
    a {
      display: block;
      border: 1px solid #e0e0e0;
      padding: 5px 8px;
      background: #f0f0f0;
      font-size: 14px;
      text-decoration: none;
      font-weight: 700;
      border-radius: 3px 3px 0 0;
      &.active {
        background: #fff;
        color: #222;
        border-bottom: 1px solid #fff;
        text-decoration: underline;
      }
      &:hover {
        background-color: #f6f6f6;
        text-decoration: underline;
      }
    }
  }
  .tab-content {
    h3 {
      padding: 5px 10px;
      width: auto;
      font-size: 16px;
      color: #069;
      background: #f2f2f2;
      text-align: left;
    }
    ul {
      margin: 0 0 20px 15px;
      padding: 0;
      list-style: none;
      overflow: hidden;
    }
  }
  .tab-pane {
    max-height: 600px;
    overflow-y: scroll;
  }
}
aside {
  .card {
    border-top: 3px solid #0098da;
    background: #fff url(../images/section_bg.jpg) no-repeat top right;
    &.action {
      border-top: 1px solid rgba(0, 0, 0, 0.125);
    }
  }
  .top-questions {
    background: #f9f9f9 url(../images/question-icon.png) no-repeat 95% center;
  }
}
.card {
  &.card-action {
    background: #fff url(../images/section_bg.jpg) no-repeat top right;
  }
}
/* ----------------------------------------------------------------------------
    Page Media Queries
----------------------------------------------------------------------------- */
// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
  #banner {
    h1 {
      font-size: 30px;
    }
  }
  #steps-wrap {
    .icon {
      h3:before {
        display: block;
        margin: 0 auto 10px auto;
        width: 60px;
        height: 60px;
      }
      h3.step-2:before {
        background-position: -60px 0;
      }
      h3.step-3:before {
        background-position: -120px 0;
      }
    }
  }
  #course-feature {
    .feature {
      padding-left: 100px;
    }
    .support {
      background: transparent url(../images/support-icon.png) no-repeat 0 5px;
    }
    .test {
      background: transparent url(../images/test-icon.png) no-repeat 0 5px;
    }
  }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
  .container-fluid.but-fixed {
    padding-left: 30px;
    padding-right: 30px;
  }
  #header a.logo {
    img {
      display: block;
      float: left;
      max-width: 80px;
      margin-right: 15px;
    }
    span.big {
      display: inline-block;
      font-size: 1.9em;
    }
    span.small {
      display: inline-block;
    }
  }
  #header-wrap {
    border-top: 3px solid $theme-primary;
    #header #support {
      display: block;
      span.phone {
        display: inline-block;
      }
    }
  }
  #banner {
    h1 {
      font-size: 40px;
    }
  }
}
// roughtly 900px/16px
@media only screen and (min-width: 56.25em) {
  #header a.logo {
    width: 50%;
    span.small {
      font-size: 18px;
    }
  }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
  #header a.logo {
    width: 50%;
    span.small {
      font-size: 18px;
    }
  }
  #header #support {
    display: block;
    span.phone {
      display: inline-block;
    }
  }
  #header-wrapper {
    background: #fff url(../images/header-bg.jpg) no-repeat 50% 50% / cover;
  }
  #banner {
    h1 {
      font-size: 50px;
    }
  }
  #steps-wrap {
    .icon {
      h3 {
        font-size: 1.2em;
        &:before {
          width: 120px;
          height: 120px;
        }
        &.step-2:before {
          background-position: -120px 0;
        }
        &.step-3:before {
          background-position: -240px 0;
        }
      }
    }
  }
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
}
